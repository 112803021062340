import { ToastrService } from 'ngx-toastr';

export const ShowError = (status: number, toas :ToastrService, message: string, _timOut=3000): any => {
  if(status == 0){
    toas.error("", 'check your internet connection', {
     timeOut: _timOut,
      positionClass: 'toast-top-center',
      });      
  } 
  else{
    toas.error("", message, {
      timeOut: _timOut,
      positionClass: 'toast-top-center',
    });   
  }  
  return false;
};
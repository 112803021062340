import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotfoundComponent } from './component/page-notfound/page-notfound.component';
import { CommonModule } from '@angular/common';
import { OtpPageComponent } from './component/otp-page/otp-page.component';
import { SafariBlockPageComponent } from './component/safari-block-page/safari-block-page.component';
import { HomeComponent } from '@baseUrl/src/app/component/homepages/home/home.component';
import { StreamPageComponent } from '@baseUrl/src/app/component/stream-page/stream-page.component';
import { SystemMainteneceComponent } from '@baseUrl/src/app/component/system-maintenece/system-maintenece.component';
import { UserBlockComponent } from './component/user-block/user-block.component';
import { CustomPreloadingStrategy }  from './custom-preloading-strategy';
const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }, 
  {
    path: 'home',
    component: HomeComponent
  },    
  {
    path: "account",
    loadChildren: () => import('@components/signin-upPages/account.module').then((m) => m.AccountModule),
    data: { preload: true }
  },
  {
    path: "dashboard",
    loadChildren: () => import('@components/homepages/home.module').then((m) => m.HomeModule),
    data: { preload: true }
  },
  {
    path: "d-page",
    loadChildren: () => import('@components/dpages/dpage.module').then((m) => m.DpageModule),
    data: { preload: true }
  },
 
  {
    path: "service",
    loadChildren: () => import('@components/servicepages/service.module').then((m) => m.ServiceModule),
    data: { preload: true }
  },
  {
    path: "promotion",
    loadChildren: () => import('@components/promotionpages/promotion.module').then((m) => m.PromotionModule),
    data: { preload: true }
  },
  {
    path: "profile",
    loadChildren: () => import('@components/profilepages/profile.module').then((m) => m.ProfileModule),
    data: { preload: true }
  },
  {
    path: "wallet",
    loadChildren: () => import('@components/walletpages/wallet.module').then((m) => m.WalletModule),
    data: { preload: true }
  },
  {
    path: "game",
    loadChildren: () => import('@components/gamepages/game.module').then((m) => m.GameModule),
    data: { preload: true }
  },
  {
    path: "agent",
    loadChildren: () => import('@components/agents/agents.module').then((m) => m.AgentsModule),
    data: { preload: true }
  },
  {
    path: "level",
    loadChildren: () => import('@components/levelpages/levelpages.module').then((m) => m.LevelpagesModule),
    data: { preload: true }
  },  
  {
    path: 'user-block', component: UserBlockComponent,
    data: { preload: true }
  },
  {
    path: 'otp', component: OtpPageComponent,
    data: { preload: true }
  },  
  {
    path: 'stream', component: StreamPageComponent,
    data: { preload: true }
  },  
  {
    path: 'safari-block', component: SafariBlockPageComponent,
    data: { preload: true }
  },
  {
    path: 'sytem-maintence/:message', component: SystemMainteneceComponent,
    data: { preload: true }
  },
  {
    path: '**', component: PageNotfoundComponent
  }

];

@NgModule({

  // imports: [CommonModule, RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  imports: [CommonModule, RouterModule.forRoot(routes, {preloadingStrategy: CustomPreloadingStrategy})],
  exports: [RouterModule],
  providers: [ CustomPreloadingStrategy ]
})
export class AppRoutingModule { }


<div class="single-goal single-goal-one">
    <div class="row">
        <div class="col-7 pr-0">            
            <div class="details">
                <span>{{ contactNo}}</span>              
            </div>
        </div>
        <div class="col-5 pl-0">
            <div class="circle-inner circle-inner-one">
                <ul class="social-area">
                    <li>
                        <a href="tel:{{contactNo}}{{openUrl}}" class="phone">
                            <i class="fas fa-phone"></i>                      
                        </a>     
                    </li>
                    <li>
                        <a [href]="'viber://chat?number='+viberNo+''+openUrl | safeUrl" *ngIf="isIOS==false" class="viber">
                            <i class="fab fa-viber"></i>                        
                        </a>
                    </li>
                    <li>
                        <a [href]="'viber://chat?number='+contactNo+''+openUrl | safeUrl" *ngIf="isIOS==true" class="viber">
                            <i class="fab fa-viber"></i>
                        </a>
                    </li>
                </ul>
            </div>
           
          
        </div>
    </div>
</div>    
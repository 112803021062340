

<div class="header-area bg-base" [ngClass]="{'not_border' : !showBorder}">   
    <div class="container">      
        <div class="row pb-2">
            <div class="col-sm-3 col-3 align-self-center">
              <app-language-page></app-language-page>    
            </div>            
            <div class="col-sm-6 col-6 text-center align-self-center text-center" *ngIf="!showLogo">
              <h3 class="text-white"> <ng-content></ng-content>  </h3> 
             </div>
             <div class="col-sm-6 col-6 text-center align-self-center text-center" *ngIf="showLogo">                  
                 <a [routerLink]="['/home']" class="logo">
                   <img src="assets/img/logo.png" alt="logo"  height="36">
                 </a>
             </div>
            <div class="col-sm-3 col-3 d-flex justify-content-end">
                <ul class="header-right">
                    <!-- <li>                   
                      <app-language-page></app-language-page>    
                    </li> -->
                    <li *ngIf="showNoti">
                      <a [routerLink] = "['/dashboard/noti-list']" class="pt-2">
                          <i class="far fa-bell text-white"></i>                       
                          <span class="badge">{{ notiCount }}</span>
                       </a>                    
                    </li>
                    <li *ngIf="hideRefresh" >                      
                      <i class="fas fa-sync-alt" style="font-size: 22px; color: #fff" (click)="refreshPage()"></i>
                    </li>
                    <li *ngIf="btnAdd">                      
                      <i class="fas fa-plus-circle" style="margin-top: 6px;font-size: 22px; color: #fff" (click)="Add()"></i>
                    </li>                 
                </ul>
            </div>
        </div>
    </div>
   </div>
   <div class="py-2"></div>
  
   <!-- navbar end -->
  <!-- <div class="ba-navbar">
    <div class="ba-navbar-user text-center">
        <div class="menu-close">
            <i class="far fa-times-circle text-danger"></i>
        </div>
        <div class="thumb">
            <img [src]="(userProfileModel?.imageUrl == null || userProfileModel?.imageUrl == '') ? 'assets/img/user.png' : userProfileModel?.imageUrl" alt="user">
        </div>
        <div class="details" *ngIf="isUserLoggedIn">
            <h5> {{ userProfileModel?.name }} </h5>
            <p> {{ userProfileModel?.phone_no | phoneformat }} </p>
        </div>
    </div> 
    <div class="ba-balance-inner mb-3 text-end align-self-center"  *ngIf="isUserLoggedIn">
        <div class="icon">
          <img src="assets/img/icon/money.png" alt="" width="34">     
        </div>
        <div style="padding: 0px 10px;">
          <h5 class="title text-muted" style="padding: 0px;"> {{ 'balance' | translate }}</h5>
          <h5 class="amount" style="padding: 5px; font-weight: 900;"> {{ userProfileModel?.balance | number : '1.2-2'}} </h5>
        </div>
    </div> 
    <div class="ba-main-menu">
        <h5>Menu</h5>
        <ul>         
            <li><a [routerLink]="['/home']">Home</a></li>
            <li><a [routerLink]="['/wallet']"  *ngIf="isUserLoggedIn">Wallet</a></li>
            <li><a [routerLink]="['/service/service-phone']">Services</a></li>
            <li><a [routerLink]="['/promotion']"  *ngIf="isUserLoggedIn">Promotion</a></li>
            <li><a [routerLink]="['/profile/me-page']">Profile</a></li>       
            <li><a class="mt-4" (click)="logOut()">Logout</a></li>
        </ul>
    </div>
  </div> -->
  <!-- navbar end -->
<headerbar (myEvent)="refreshPage()">{{'serviceTap' | translate }}</headerbar>
<div class="content-wrapper">
    <h3 class="service-color">{{ 'serviceTime1' | translate}} </h3>
    <!-- TopUp/Withdrawal Help -->
    <ng-container *ngIf="servicePhoneList?.kpay">
        <h3 class="service-title">KPAY {{  'service_transaction' | translate }}</h3>
        <div class="row" *ngFor="let servicePhoneObj of servicePhoneList.kpay">
            <div class="col-12">
                <app-contact-box [contactNo]="servicePhoneObj.phone_no" [viberNo]="servicePhoneObj.viber">
                </app-contact-box>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="servicePhoneList?.wave">
        <h3 class="service-title">WAVE {{  'service_transaction' | translate }}</h3>
        <div class="row" *ngFor="let servicePhoneObj of servicePhoneList.wave">
            <div class="col-12">
                <app-contact-box [contactNo]="servicePhoneObj.phone_no" [viberNo]="servicePhoneObj.viber">
                </app-contact-box>
            </div>
        </div>
    </ng-container>

    <!-- App Error -->
    <ng-container *ngIf="servicePhoneList?.service_error">
        <h3 class="service-title">{{ 'service_error' | translate }}</h3>

        <div class="row" *ngFor="let servicePhoneObj of servicePhoneList?.service_error">
            <div class="col-12">
                <app-contact-box [contactNo]="servicePhoneObj.phone_no" [viberNo]="servicePhoneObj.viber"> </app-contact-box>
            </div>
        </div>
    </ng-container>

    <!-- cumstomer service -->
    <ng-container *ngIf="servicePhoneList?.customer_service">
        <h3 class="service-title">{{ 'customer_service' | translate }}</h3>

        <div class="row" *ngFor="let servicePhoneObj of servicePhoneList">
            <div class="col-12" *ngIf="servicePhoneObj.title == 'customer_service'">
                <app-contact-box [contactNo]="servicePhoneObj.phone_no" [viberNo]="servicePhoneObj.viber"> </app-contact-box>

            </div>
        </div>
    </ng-container>

    <!-- cumstomer service -->
    <ng-container *ngIf="servicePhoneList?.channel_link">
        <h3 class="service-title" style="text-align:center">{{ 'channel_service' | translate }}</h3>

        <div class="row" *ngFor="let servicePhoneObj of servicePhoneList?.channel_link">
            <div class="col-12" >
                <div class="channel__box">
                    <!-- <a [href]="servicePhoneObj.telegram+openUrl | safeUrl" class="mr-3" target="_blank">
                        <img class="service-image" src="assets/img/telegram.png" alt="">
                    </a> -->
                    <a [href]="'https://t.me/+'+servicePhoneObj.telegram | safeUrl" class="mr-3" target="_blank">
                        <img class="service-image" src="assets/img/telegram.png" alt="">
                    </a>
                    <a [href]="'viber://chat?number='+servicePhoneObj.viber+''+openUrl | safeUrl" *ngIf="!isIOS">
                        <img class="service-image" src="assets/img/viberbutton.png" alt="">
                    </a>
                    <a [href]="'viber://chat?number='+servicePhoneObj.phone_no+''+openUrl | safeUrl" *ngIf="isIOS">
                        <img class="service-image" src="assets/img/viberbutton.png" alt="">
                    </a>

                    <a [href]="servicePhoneObj.fbpage | safeUrl" class="ml-3" target="_blank" *ngIf="!isMobile">
                        <img class="service-image" src="assets/img/facebook.png" alt="">
                    </a>

                    <ng-container *ngIf="isMobile">
                        <a [href]="'fb://profile/'+servicePhoneObj.fbpage_id | safeUrl" class="ml-3" target="_blank"
                            *ngIf="isIOS">
                            <img class="service-image" src="assets/img/facebook.png" alt="">
                        </a>

                        <a [href]="'fb://page/'+servicePhoneObj.fbpage_id | safeUrl" class="ml-3" target="_blank"
                            *ngIf="!isIOS">
                            <img class="service-image" src="assets/img/facebook.png" alt="">
                        </a>
                    </ng-container>


                    <!-- <a [href]="'https://m.facebook.com/messages/compose?ids='+servicePhoneObj.messanger_id | safeUrl" class="ml-3" target="_blank" *ngIf="!isMobile">
                        <img class="service-image" src="assets/img/facebook.png" alt="" >
                    </a>

                    <a [href]="'https://m.me/'++servicePhoneObj.messanger_id | safeUrl" class="ml-3" target="_blank" *ngIf="isMobile">
                        <img class="service-image" src="assets/img/facebook.png" alt="" >
                    </a> -->


                </div>

            </div>
        </div>
    </ng-container>
    <div class="back__wrapper">
        <button class="btn" (click)="goBack()">
           <!-- <ion-icon name="arrow-back"></ion-icon> -->
        </button>
    </div>
</div>

<label class="single-input-wrap">
  <div class="input-group">
    <div class="input-group-prepend">
      <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false"
        (click)="getCountryPhoneCodePrefix()">
        <button id="button-basic" dropdownToggle type="button" class="btn btn-group dropdown-toggle" data-toggle="dropdown">  
          <img src="{{imageUrl}}" alt="" width="20px" height="20px">&nbsp;{{ prefix }} 
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <a class="dropdown-item" *ngFor="let registerCountryCodeobj of registerCountryCode; let i = index;"
            [class.active]="selectedIndex == i" (click)="dropdown.isOpen = !dropdown.isOpen"
            (click)="selectedRegisterCountryCode(i)">
            <li role="menuitem">
              <img class="mr-1" src="{{registerCountryCodeobj.imageUrl}}" alt="" width="30px" height="30px">&nbsp;{{registerCountryCodeobj.prefix}}{{registerCountryCodeobj.countryName}}
            </li>
          </a>
         
        </ul>
      </div>
    </div>
     <input type="tel" class="form-control" (keyup)="checkPhoneNumber()" (keyup.enter)="checkPhoneNumber()" (focus)="checkPhoneNumber()" 
     [(ngModel)]="phoneValue" [ngModelOptions]="{standalone: true}"  placeholder="{{ 'phoneNumberHint' | translate}}"/> 
  </div>      
  <p id="phoneErr" class="pt-2 text-danger text-center"> </p>
</label>

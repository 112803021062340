<ng-container *ngIf="isOtpService==false"> 
    <headerbar>OTP {{formPage}}</headerbar>    
    <div class="content-wrapper" >        
        <div class="signin-area">
            <div class="container">
                <div class="signin-area-inner text-center mb-4">
                    <img src="assets/img/login_background.png" alt="img">
                    <h3>Verications</h3>
                    <p>{{ 'otpdescription' | translate}} <br> {{phoneNumber}}</p>
                    <p>{{ 'otptitle' | translate}}</p>
                </div>
                <form class="verify-form-inner text-center">
                    <code-input  [initialFocusField]="focus" [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeCompleted($event)"
                     #codeInput  (keyup.enter)="validateOtp()" ></code-input>
                    <p id="passErr" class="text-danger" style="text-align: center; width: 100%;"></p>        
                    <div class="counterTimer" *ngIf="counterTimer > 0">{{counterTimer}}</div>                  
                    <div class="text-right mt-2" (click)="getOtp()" *ngIf="counterTimer == 0">
                        <p>I didn’t receive code. Resend Code</p>
                    </div>
                    <a class="btn-large btn-blue text-white w-100 mt-2" (click)="checkOtp()">{{ 'next' | translate}}</a>
                </form>
                <div class="err__msg my-4">
                    <div (click)="goToServicePhone()" class="text__link"> {{'code_err_msg' | translate}} {{'help' | translate}}</div>
                </div>        
            </div>
        </div>   
    </div>
</ng-container>
<ng-container *ngIf="isOtpService==true">
    <app-otp-service-phone (backemit)="showOptPage($event)"></app-otp-service-phone>
</ng-container> 
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
  
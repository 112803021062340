import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Column } from '@baseUrl/src/app/shared/basic-components/column';
import { EncryptionDecryptionService } from './encryption-decryption.service';
import axios from 'axios';
import { ApiTokenService } from './api-token.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  isLogged: any;
  gameProviderList: any;
  token: any;
  userProfileBalance: any;
  mainWallet: any;
  subject = new Subject<string>();
  public spinner: any;
  
  constructor( 
    public toastr: ToastrService,   
     
    private storage: LocalStorageService,
    public spinnerService: NgxSpinnerService, 
    public enc_dec: EncryptionDecryptionService,
    private apiTokenServer: ApiTokenService
    ){
    
    this.spinner= {
      bdColor: "rgb(6,56,107,0.8)",
      size: "medium",
      color: "#fff",
      type: "ball-spin-clockwise",
      fullScreen: false,
      name: "submitting"
    }

  }

  ngOnInit(): void {   
  }
  //spinner
  public Loading(name: string){    
    this.spinnerService.show(name);
  }
  public unLoading(name: string){
    this.spinnerService.hide(name);
  }
  //endspinner
  public HandleSuccess(status: number, message: string) {
    if(status == 423) {
    }
    // if (status == 400) {
    //   this.toastr.error("", 'Invalid!', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //   });
    //   return;
    // }
    else{
      this.toastr.error("", message, {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      return;
    }  
  }

  ToastrService
  public ShowError(toas :ToastrService, message: string, _timOut=3000) {
    toas.error("", message, {
      timeOut: _timOut,
      positionClass: 'toast-top-center',
    });
    return;
  }
  public HandleError( message: string) {
    this.toastr.error("", message, {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
    return;
    // if(status == 423) {
    // }
    // if (status == 400) {
    //   this.toastr.error("", 'Invalid!', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //   });
    //   return;
    // }
    // else{
    //   this.toastr.error("", message, {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //   });
    //   return;
    // }  
  }

  public async RefreshToken(){        
          let token_key= this.storage.retrieve("tokenKey");   
          let key= await this.enc_dec.TokenAesKeyDecrypt(token_key);          
          //end tokenkey    
          let rsaEnc = await this.enc_dec.RSAEncryptKey(key); 
          const encKey= encodeURIComponent(rsaEnc);            
          let token = this.storage.retrieve('token'); 
          let formData = new FormData();
          formData.append('data', token);  
          let data = axios.post(`User/RefreshToken?secretKey=${encKey}`,formData)
          .then(async response=> {   
           // console.log(JSON.stringify(response.data)) 
            let data= response.data;        
            if(data.statusCode == 200){  
             // console.log(JSON.stringify(data))               
                  //let users = await this.enc_dec.decryptAES(key, response.data.data);                     
                  this.storage.store('token', response.data.data); 
                  return true;
             }             
             else{
              this.storage.clear('token');
              this.toastr.error("", response.data.message, {
                timeOut: 6000,
                positionClass: 'toast-top-center',
              });
              return false;              
             }    
          })          
          .catch(error=> {  
            return false;    
          });
          return data;
  }
 
  async getUserProfile() {           
    let userData = await this.apiTokenServer.get(`User/userprofile`, true)        
    .then(async response=> {           
      if(response.data.statusCode == 200){
            let userModel = JSON.parse(response.data.data);
            this.storage.store("userProfile", userModel);
            this.storage.store('isUserLoggedIn',true);
            return userModel;
       }             
       else{
        this.toastr.error("", response.data.message, {
          timeOut: 5000,
          positionClass: 'toast-top-center',
        });
        this.storage.store('isUserLoggedIn',false);
        return null;              
       }    
    })          
    .catch(async error=> { 
      this.storage.store('isUserLoggedIn',false); 
      return null;           
    });
    return userData;
  }
  //gameBalance
  async getGameUserBalance(providerCode)
  {               
    let data = await this.apiTokenServer.get(`GSApi/getBalance?providerCode=${providerCode}`)        
    .then(async response=> {  
      if(response.status == 200 && response.data.errMsg == "SUCCESS"){
            //this.storage.store(providerCode+"GameUserBalance",response.data);  
            return response.data;
       } 
       if(response.data.errCode == '81' && response.data.errMsg == "MEMBER_NOT_FOUND"){
        let member = await this.createGSGameMember();
        if(member == "SUCCESS"){
          this.getGameUserBalance(providerCode);
          return;
        }
        return response.data;
       }         
       else{        
        return response.data;              
       }    
    })          
    .catch(async error=> {      
      return 0;           
    });
    return data;
  }
  async createGSGameMember(){
   let data=  await this.apiTokenServer.get(`GSApi/createPlayer`)       
    .then(async response=> { 
      if(response.data.errMsg == "SUCCESS"){
        return "SUCCESS";
      }    
    })          
    .catch(async error=> { 
      return "null"; 
    });
    return data;
  }

  // getCloseDateTime() {
  //   let headers = new HttpHeaders();
  //   return this.http.get('threedconfig/3d_close_time', { headers: headers });
  // }

 
  
 


}

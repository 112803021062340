import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiTokenService,} from '@services/index';
import { interval } from 'rxjs';


@Component({
  selector: 'app-system-maintenece',
  templateUrl: './system-maintenece.component.html',
  styleUrls: ['./system-maintenece.component.scss']
})
export class SystemMainteneceComponent implements OnInit {
  public errorMessage: any;

  constructor(private _route: ActivatedRoute, 
    private apiTokenServer: ApiTokenService,
    private _router: Router) {

  }

  ngOnInit(): void {
    this.errorMessage = this._route.snapshot.paramMap.get('message');
    this.refreshPage();
  }

  refreshPage() {
    // interval(2*60*1000).subscribe(x => {
    //   this.apiTokenServer.getCloseDateTime()
    //     .subscribe((res) => {
    //       this._router.navigate(['home']);
    //     })
    // });

  }

  checkX(x) {
    //console.log(x)
  }
}

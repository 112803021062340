export { AppNavigationBarComponent } from "./navigation-bar/navigation.component";
export { HeaderMenuBarComponent } from "./commonappbar/header-menu-bar/header-menu-bar.component";
export { HeaderbarComponent } from "./commonappbar/headerbar/headerbarcomponent";
export { LanguagePageComponent } from "./language-page/language-page.component";
export { LanguageTextPageComponent } from "./language-text-page/language-text-page.component";
export { AccountLoginComponent } from './account-login/account-login.component'
export { AdsSliderComponent } from './ads-slider/ads-slider.component';
export { PageHeaderDetailComponent } from './page-header-detail/page-header-detail.component';
export { OtpServicePhoneComponent } from './otp-service-phone/otp-service-phone.component';
export { SharedTableComponent } from './shared-table/shared-table.component';
export { QrReaderComponent } from './qr-reader/qr-reader.component';
export { AppPhonePickerComponent } from './app-phone-picker/app-phone-picker.component';
export { GameWalletAccountComponent, } from './game-wallet-account/game-wallet-account.component';
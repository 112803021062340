

<div class="container-fluid mb-lg-3" *ngIf="!isUserLoggedIn">
  <div class="ba-balance-inner" (click)="loginAccount()">
    <div class="icon">
      <i  class="fas fa-user-circle account-login"></i>      
    </div>    
    <h5 class="title"> {{ 'click_to_login' | translate}} </h5>  
  </div>
</div>
<div class="container mb-3" *ngIf="isUserLoggedIn">   
  <div class="ba-balance-inner  col-12 text-end">    
    <div class="icon" (click)="openWalletPage()">
      <img src="assets/img/icon/money.png" alt="" width="34" *ngIf="!showProfile">        
      <img alt="" src="./assets/img/profile.png" width="34" *ngIf="userProfileModel.imageUrl == null && showProfile" style="opacity: .5;"> 
      <img alt="" [src]="userProfileModel.imageUrl" width="60" style="border-radius: 100%; padding: 0px;" *ngIf="userProfileModel.imageUrl != null && showProfile"> 
    </div>  
    <div class="row" >
      <div class="col-8" *ngIf="showProfile">
        <div  class="pl-2">
          <div class="balance__wrapper">
            <h5>{{userProfileModel.name}}  </h5>    
        </div> 
        <h3 style="font-size: 14px;">{{userProfileModel.phone_no}} </h3>
        </div>
       
      </div>
      <div class="col-8" *ngIf="!showProfile">
        <div  class="pl-2">
          <div class="balance__wrapper">
            <h5> {{'balance' | translate}}  </h5>             
            <span class="text-eye">            
              <a [routerLink]="" (click)="showPassword(!showAmount)">
                <i class="fas " [ngClass]="{'fa-eye': showAmount, 'fa-eye-slash': !showAmount}"></i>            
              </a>
            </span>
        </div>
      
        <h3 *ngIf="showAmount==true" class="wallet-amount" >{{
          showBalance | number : '1.0-2'}}{{'ks'| translate}}</h3>
        <h3 *ngIf="showAmount==false" class="wallet-amount-star" >*****
        </h3>
        </div>
       
      </div>
      <div class="col-4 d-flex justify-content-end" *ngIf="!hideButton"  (click)="openWalletPage()">
        <span class="wallet-right-arrow">
          <i class="fa fa-angle-right" style="font-size: 14px;"></i>         
        </span>
      </div>
    </div> 
  
  </div>
</div>

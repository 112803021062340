<!--Hey! This is the original version
of Simple CSS Waves-->
<div id="appCapsule">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-12 ">
                <h4 class="text-danger text-center" style="font-size: 20px;">{{message}}</h4>
                <div class="d-flex justify-content-center">
                    <a [routerLink]="['/account/login']" class="btn btn-primary user_block_btn"> {{ 'loginButton' | translate}}</a> 
                </div>
                
            </div>
        </div>
    </div>
  
  </div>
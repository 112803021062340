import { Component, OnInit,  Injectable, Input, Output, EventEmitter } from '@angular/core';
import { ApiTokenService,  } from "@services/index";
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-phone-picker',
  templateUrl: './app-phone-picker.component.html',
  styleUrls: ['./app-phone-picker.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class AppPhonePickerComponent implements OnInit {

  regExpressionList :any;   
  registerCountryCode: any;  
  selectedIndex: any=0;
  imageUrl= "assets/img/my_flag.png";
  @Input() prefix= "+95";
  localRegisterCountryCode: any;
  regularExpression= '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$';

  @Input()  phoneValue= ""; 
  @Input()  regularExpressionPhone = '';
  @Output() phoneValueChange = new EventEmitter<string>();
  @Output() regularExpressionPhoneChange = new EventEmitter<string>();
  @Output() prefixChange = new EventEmitter<string>();
 
  constructor( private translateService: TranslateService,
     private toastr: ToastrService, 
     private storage: LocalStorageService, 
     
     private apiTokenServer: ApiTokenService,)
    {
     
    }
  async ngOnInit(): Promise<void> {   
    let localPhone = await this.storage.retrieve('localPhoneValue');
    if(localPhone != null && localPhone != undefined){
      this.phoneValue = localPhone;
    } 
    let localpre = await this.storage.retrieve('localPhonePrefix');
    this.registerCountryCode = await this.getCountryPhoneCodePrefix();
    if(localpre == null){
      this.selectedIndex = 0;
      this.storage.store('localPhonePrefix', '+95');
    }else{
         this.selectedIndex = 0;
         for(let j=0;j< this.registerCountryCode.length;j++){
          if((this.registerCountryCode[j].prefix == localpre) && j== 0){         
            return;
          }
          if((this.registerCountryCode[j].prefix == localpre) && j>0){
            this.selectedIndex= j;
            return;
          }
         }
    }
    this.regularExpressionPhoneChange.emit(this.regularExpression);
    this.prefixChange.emit(this.prefix);
    if (this.registerCountryCode.length > 0) {   
      this.prefix= this.registerCountryCode[this.selectedIndex].prefix;
      this.imageUrl= this.registerCountryCode[this.selectedIndex].imageUrl;
      this.regularExpression = this.registerCountryCode[this.selectedIndex].regularExpression 
    }
    this.prefix = this.storage.retrieve('localPhonePrefix');    
  }
  changeRegisterCountryCode(){
      this.prefix= this.registerCountryCode[this.selectedIndex].prefix;
      this.imageUrl = this.registerCountryCode[this.selectedIndex].imageUrl;
      this.regularExpression = this.registerCountryCode[this.selectedIndex].regularExpression;    
      this.regularExpressionPhoneChange.emit(this.regularExpression);
      this.prefixChange.emit(this.prefix);
      this.phoneValueChange.emit(this.phoneValue);
      this.storage.store('localPhonePrefix',this.prefix);
      
    }
  selectedRegisterCountryCode(index: number) {
      this.selectedIndex = index;
      this.changeRegisterCountryCode();
    }

 async getCountryPhoneCodePrefix() {  
    
      let response ='';
      let localData =  await this.storage.retrieve('localRegisterCountryCode');
      if(localData != null && localData != undefined){
        response = localData;
      }       
      response = await this.apiTokenServer.getStr(`registerphoneprefix/getRegisterPhoneMobileList`)
     .then(response => {      
         this.storage.store('localRegisterCountryCode',  response.data); 
         return response.data;       
      })
     .catch(error => {
         return null;
     });
     return response;
      
    }
  checkPhoneNumber()
  { 
    
    $("#phoneErr").html("");
    if(this.phoneValue != ""){
      this.phoneValueChange.emit(this.phoneValue);
      this.storage.store('localPhoneValue', this.phoneValue);
    } 
    if(this.phoneValue.length == 0) 
    {
        var phoneRequired = this.translateService.instant("requiredFiled");
        phoneRequired =  phoneRequired.toString().replace("{{value}}", this.translateService.instant("phoneNumberHint"));
        $("#phoneErr").html("<i class='fas fa-exclamation-circle text-danger'></i>&nbsp;"+phoneRequired);
        return false;
    }
    let pattern = RegExp(this.regularExpressionPhone);// /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/;
    if (!pattern.test(this.phoneValue)) {
       $("#phoneErr").html("<i class='fas fa-exclamation-circle text-danger'></i>&nbsp;"+ this.translateService.instant("phoneInvaild"));
       return false;
    }
    return true;    
  }
  

}

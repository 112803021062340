<div class="main__wrapper">
    <!-- <div class="overlay mb-2">
        <marquee direction="left">FunApp 24 သည် ၂၄နာရီ ငွေသွင်းငွေထုတ်လုပ်နိုင်ပါသည်။ (2D 95ဆ /12:00 Section 90ဆ/3D
            700ဆ/Round 10ဆ ) လူကြီးမင်းတို့ အကောင့်လုံခြုံ‌ရေးကို ဦးစားပေးဆောင်ရွက်ထားပါသည်။</marquee>
    </div> -->
    <div class="main__content pt-3">
        <div class="header__wrapper">
            <div class="d-flex justify-content-between">
                <div class="left__content">
                    <div class="logo__content">
                        <div class="logo__img">
                            <img src="assets/img/icon.png">
                        </div>
                        <h3 class="logo__name">
                            <span>မြန်ဆန်ယုံကြည်</span>
                            <span>မြန်ဆန်ယုံကြည်</span>
                        </h3>
                        <h4 class="logo__def">FunApp 24</h4>
                    </div>

                </div>
                <div class="middle__content">
                    <div class="middle__content1">
                        <img src="assets/img/stream/slide1.png">
                        <!-- <h2 class="middle__txt1">

                            3D အဆ 700
                        </h2>
                        <h5>
                            ပတ်လည် 10 ဆ
                        </h5> -->
                    </div>

                    <div class="middle__content2" *ngIf="streamDatas?.moderninternet[0] as moderninternet">
                        <h3>
                            <span class="highlight-txt">Modern - </span>
                            <span>{{moderninternet.modern}}</span>
                        </h3>

                        <h3>
                            <span class="highlight-txt">Internet - </span>
                            <span>{{moderninternet.internet}}</span>
                        </h3>
                    </div>


                </div>
            </div>

            <div class="right__wrapper shape-outer rabbet">
                <div class="shape-inner rabbet">
                    <p class="date">
                        {{todaydate | date : 'dd/MM/yyyy'}}
                    </p>
                    <p class="time">
                        {{clock}}
                    </p>
                </div>

            </div>
        </div>

        <div class="content__wrapper">
            <div class="content__left">
                <div class="steam__num shape-outer rabbet">
                    <div class="shape-inner rabbet">
                        <div id="livePage">
                            <div class="container">
                                <div id="result" class="resultStyle">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="invite-code" *ngIf="streamDatas?.referralCode.length > 0 as referralCode">
                    <div>မိတ်ဆက်ကုဒ်&nbsp;-&nbsp; </div>
                    <div class="d-flex flex-column">
                        <span style="    margin-bottom: 15px;"  *ngFor="let code of referralCode"> {{code.referral_code}}</span>
                        <!-- <span>&nbsp;-&nbsp; {{referralCode[1].referral_code}}</span> -->
                    </div>
                </div>
            </div>
            <div class="content__middle">
                <div>
                    <div class="set__num shape-outer rabbet">

                        <div class="shape-inner rabbet">
                            <span>Set</span>
                            <p id="setId"></p>

                        </div>
                    </div>

                    <div class="set__num shape-outer rabbet">
                        <div class="shape-inner rabbet">
                            <span>Value</span>
                            <p id="valueId"></p>

                        </div>
                    </div>
                </div>

                <div class="slider">

                    <owl-carousel-o [options]="customOptions">
                        <ng-container>
                            <ng-template carouselSlide id="1">
                                <div class="slide1">
                                    <img src="assets/img/stream/slide2.png" />
                                </div>

                            </ng-template>
                            <ng-template carouselSlide id="2">
                                <div class="slide1">
                                    <img src="assets/img/stream/slide3.png" />
                                </div>

                            </ng-template>
                            <ng-template carouselSlide id="3">
                                <div class="slide1">
                                    <img src="assets/img/stream/slide4.png" />
                                </div>

                            </ng-template>
                                       <ng-template carouselSlide id="4">
                                <div class="slide1">
                                    <img src="assets/img/stream/slide5.png" />
                                </div>

                            </ng-template>
                            <ng-template carouselSlide id="5">
                                <div class="slide1">
                                    <img src="assets/img/stream/slide6.png" />
                                </div>

                            </ng-template>
                            <ng-template carouselSlide id="6">
                                <div class="slide1">
                                    <img src="assets/img/stream/slide7.png" />
                                </div>

                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>

            <div class="content__right">
                <div class="content__right--inner">
                    <table class="table__wrapper">
                        <tr>
                            <th class="title1">ထီပေါက်စဥ်</th>
                            <th>10:30</th>
                            <th>12:01</th>
                            <th>02:30</th>
                            <th>04:30</th>
                        </tr>
                        <tr *ngFor="let num of streamDatas?.tableData">
                            <td>{{num.for_date_time}}</td>
                            <td>{{num.tenAM ? num.tenAM : '-'}}</td>
                            <td>{{num.twelvePM ? num.twelvePM : '-'}}</td>
                            <td>{{num.twothirtyPM ? num.twothirtyPM : '-'}}</td>
                            <td>{{num.fourthirtyPM ? num.fourthirtyPM : '-'}}</td>

                        </tr>
                    </table>
                </div>


                <div class="channel__wrapper">
                    <div class="channel__content">
                        <div class="channel__log">
                            <img src="assets/img/viber.png">
                        </div>
                        <div *ngIf="streamDatas?.service as service">
                            <h3 class="mb-3">+{{service.viber}}</h3>
                            <h3>+{{service.messanger_id}}</h3>
                        </div>

                    </div>
                    <!-- <div class="channel__content">
                        <div class="channel__log">
                            <img src="assets/img/telegram2.png">
                        </div>
                        <h3>+959407943675,+959407943675</h3>
                    </div> -->
                    <div class="channel__content">
                        <div class="channel__log">
                            <img src="assets/img/chrome.png">
                        </div>
                        <h3>lucky2d.com</h3>
                    </div>
                    <div class="d-flex mt-3">
                        <div class="channel__log mr-3">
                            <img src="assets/img/stream/playstore.png">
                        </div>
                        <div class="channel__log mr-3">
                            <img src="assets/img/stream/safari.png">
                        </div>
                        <div class="channel__log mr-3">
                            <img src="assets/img/stream/firefox.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">

    </div>

</div>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Injector, APP_INITIALIZER } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// services
import { DtoService, CommonService } from '@services/index';

// third party modules
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxWebstorageModule } from 'ngx-webstorage';

import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CodeInputModule } from 'angular-code-input';
import { AlertModule, AlertConfig } from 'ngx-bootstrap/alert';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { TranslateService } from '@ngx-translate/core';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { LoginDeviceDialogComponent } from './component/dialog/login-device-dialog/login-device-dialog.component';
import { OtpPageComponent } from './component/otp-page/otp-page.component';
import { BackComponentComponent } from './component/back-component/back-component.component';
import { SafariBlockPageComponent } from './component/safari-block-page/safari-block-page.component';
import { SharedModule } from '@shared/shared.module';
import { StreamPageComponent } from './component/stream-page/stream-page.component';

import {ServerErrorInterceptor} from './interceptors/server-error.interceptor';
import { SystemMainteneceComponent } from './component/system-maintenece/system-maintenece.component';
import { QRCodeModule } from 'angularx-qrcode';
import { UserBlockComponent } from './component/user-block/user-block.component';

export function appInitializerFactory(translate: TranslateService, injector: Injector,) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let lang = localStorage.getItem('ngx-webstorage|locallanguage');
      let langToSet;
      
      if (lang == null) {
        langToSet = 'my';
      }
      else {
        langToSet = lang.toString().replace(/['"]+/g, '')
      }
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
        //console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        // console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    BackComponentComponent,
    AppComponent,
    OtpPageComponent,
    LoginDeviceDialogComponent,
    SafariBlockPageComponent,
    StreamPageComponent,
    SystemMainteneceComponent,
    UserBlockComponent,
  ],
  imports: [
    BsDropdownModule,
    ModalModule.forRoot(),
    AlertModule,
    ModalDialogModule,
    CodeInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); },
        deps: [HttpClient]
      }
    }),
    CarouselModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    SharedModule,
    QRCodeModule
  ],
  providers: [
    DtoService,    
    DatePipe,
    AlertConfig,
    BsModalService,
    BsDropdownConfig,
    CommonService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


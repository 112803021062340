import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { LocalStorageService } from 'ngx-webstorage';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public data: any;
  public signalrConnect: any;
  public signalrInfo: any;

  constructor(private http: HttpClient,private storage:LocalStorageService) {
    //pro
    this.signalrConnect= "https://signalr.funapp24.com/chatHub";
    this.signalrInfo = "https://signalr.funapp24.com/api/info";
  
    //dev
    // this.signalrConnect= "https://demosignalr.funapp24.com/chatHub";
    // this.signalrInfo = "https://demosignalr.funapp24.com/api/info";
    //lated api
    
  }


  private hubConnection: signalR.HubConnection
  public startConnection = () => {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.signalrConnect)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.http.get(this.signalrInfo)
          .subscribe((res: any) => {

            // this.datas=JSON.parse(res.results[0].data)
           /////////// console.warn(res);
          })
      })

      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addTransferChartDataListener = () => {
    this.hubConnection.on('transferchartdata', (data) => {
      this.data = JSON.parse(data).results[0];
       this.storage.store('sectionResultList', this.data);
    });
  }

  public getLatedData(){
    axios.get(`${this.signalrInfo}/lastDataAsync`)
    .then(response => {
      let ddd=response.data.results[0];
      this.data = ddd;
      this.storage.store('sectionResultList', this.data);
    })
    .catch(error => {
      ////////console.warn(error.response.message);
    })
  }
}

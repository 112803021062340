import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  async ngOnInit(): Promise<void> {
      let lang  =  await localStorage.getItem('Language');
      var element = document.getElementById("body");   
      if(lang == 'my'){
        element.classList.remove("body__en");
        element.classList.remove("body__mm");
        element.classList.add("body__mm");        
      }
      else{
        element.classList.remove("body__mm");
        element.classList.remove("body__en");
        element.classList.add("body__en");
      }
     
  }
 
  constructor() {    
  } 
  onDeactivate() {  
     window.scrollTo(0, 0);
  }
}




import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-block',
  templateUrl: './user-block.component.html',
  styleUrls: ['./user-block.component.scss']
})
export class UserBlockComponent implements OnInit {
  message: string="";
  constructor() { 
    this.message= history.state.message;
  }

  ngOnInit(): void {
  }
  

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountLoginComponent } from '@baseUrl/src/app/shared/basic-components/account-login/account-login.component';
// library
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
// services
import { ApiTokenService } from "@services/index";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild(AccountLoginComponent) child:AccountLoginComponent;
  gameProviderList : any=[];
  notiCount: any;
  threedCloseTime:any;
  device: string='';
  fcmtoken: string='';  
  constructor(
    private activated: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private storage: LocalStorageService,    
    private apiTokenServer: ApiTokenService,
    ) {    
   
    history.pushState(null, null, null);
    history.pushState(null, null, null); 
    this.activated.queryParams.subscribe(params=> {
      this.device =  params.device;
      this.fcmtoken = params.fcmtoken; 
      if(this.device != "" && this.device != null && this.device != undefined) localStorage.setItem('device',  this.device);
    });
   
  }



  async ngOnInit(): Promise<void> {    
    if(this.fcmtoken != "" && this.fcmtoken != null && this.fcmtoken != undefined){      
      this.updateFCMtoken();
      localStorage.setItem('fcmtoken',  this.fcmtoken);       
    } 
    let isUserLoggedIn = JSON.parse(await localStorage.getItem('isUserLoggedIn'));    
    if(isUserLoggedIn){     
      this.updateFCMtoken();
    }
    this.getGameProviderList();  

    this.notiCount= this.storage.retrieve("localNewNotiCount");//localNotiCount


    

  }


  
  goToNotiList() {    
    let isUserLoggedIn= this.storage.retrieve('isUserLoggedIn');  
    if(isUserLoggedIn!=undefined && isUserLoggedIn==true){
      this.router.navigate(['/noti-list'],{replaceUrl: true});
    }else{
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
    }   
   
  }

 async getGameProviderList()
  {
    let localData = await this.storage.retrieve('gameList');
    if(localData != null && localData != undefined){
      this.gameProviderList = localData;
    }  
    this.apiTokenServer.get(`gameProvider/getGameProviderList`)
    .then(response => {
      this.gameProviderList = response.data;
      this.storage.store('gameList', response.data);
    })
    .catch(error => {

    })
    
  }

  
  needHelpPage() {
    this.router.navigate(['/service/need-help']);
  }
  
  async refreshPage(){
   this.spinner.show("refreshLoading");
   await this.ngOnInit();
   await this.child.ngOnInit();
   this.spinner.hide("refreshLoading");
  }
   async updateFCMtoken(){ 
      var token;
      token = this.fcmtoken;
      if(token == ''){
        token =  await localStorage.getItem('fcmtoken');  
      } 
      if(token == undefined  || token == null) return;
      let modelStr = token;      
      this.apiTokenServer.post(`user/updateFcmtoken`,modelStr, true) 
      .then(async response=> {         
       if(response.data.statusCode == 200)
       {   
        
       }        
       else{       
         //let message= this.translateService.instant(response.data.message)
          //ShowError(response.data.statusCode,this.toastr,message);
        }   
     })          
     .catch(async error=> {  
       //ShowError(error.status,this.toastr,error.message);
     });
   }

}

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DtoService } from 'src/app/service/dto.service';


@Component({
  selector: 'app-otp-service-phone',
  templateUrl: './otp-service-phone.component.html',
  styleUrls: ['./otp-service-phone.component.scss']
})
export class OtpServicePhoneComponent implements OnInit {

  @Output() backemit = new EventEmitter();
  servicePhoneList: any;
  localservicePhoneList: any;
  channel_link: any;
  stream_link: any;
  service_transaction: any;
  service_error: any;
  customer_service: any;
  constructor(private translateService: TranslateService, 
      
    private http: HttpClient, 
    private toastr: ToastrService, 
    private spinner: NgxSpinnerService,
    private storage: LocalStorageService,) {
  ;

  }

  public isIOS: boolean = false;
  public isMobile: boolean = false;
  ngOnInit(): void {
    this.service_transaction = 0;
    this.service_error = 0;
    this.customer_service = 0;
    this.listServicePhone();
    //console.log(navigator.userAgent)
    // this.isMobile = /Mobi/.test(navigator.userAgent);
    var hasTouchScreen = false;

    if ("maxTouchPoints" in navigator) {
      this.isMobile = navigator.maxTouchPoints > 0;
    }

  
    this.servicePhoneList = this.storage.retrieve('localservicePhoneList');
    if ([
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel'
    ].includes(navigator.platform)) this.isIOS = true;
  }

  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      this.storage.clear('token');
      this.storage.clear('isUserLoggedIn');
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  listServicePhone() {
    this.service_transaction = 0;
    this.service_error = 0;
    this.customer_service = 0;

    this.spinner.show();
    let headers = new HttpHeaders();
    this.servicePhoneList = [];
    this.servicePhoneList = this.storage.retrieve('localservicePhoneList');
    this.http.get('service/listService', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.servicePhoneList = result.reduce((acc, obj) => {
            return { ...acc, [obj.title]: [...acc[obj.title] || [], obj] }
          }, {});
         // console.log(this.servicePhoneList);
          this.service_transaction = 0;
          this.service_error = 0;
          this.customer_service = 0;
          for (let i = 0; i < this.servicePhoneList.length; i++) {
            if (this.servicePhoneList[i].title == 'service_transaction') {
              ++this.service_transaction;
            }
            if (this.servicePhoneList[i].title == 'service_error') {
              ++this.service_error;
            }
            if (this.servicePhoneList[i].title == 'customer_service') {
              ++this.customer_service;

            }

            if (this.servicePhoneList[i].title == 'channel_link') {
              ++this.channel_link;
            }

            if (this.servicePhoneList[i].title == 'stream_link') {
              ++this.stream_link;
            }

          }
          // console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response));
          this.storage.store('localservicePhoneList', this.servicePhoneList);
          this.spinner.hide();
        }
      );
  }

  goBack(){
   this.backemit.emit(false)
  }

  refreshPage(){
    this.ngOnInit();
  }


}


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import {CommonService, ApiTokenService } from "@services/index";
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-game-wallet-account',
  templateUrl: './game-wallet-account.component.html',
  styleUrls: ['./game-wallet-account.component.scss']
})
export class GameWalletAccountComponent implements OnInit {
  @Input() providerCode: any=null;  
  @Input() providerId: any=null;  
  @Output() getBalance= new EventEmitter();  
  isUserLoggedIn: boolean= false;
  gameBalance: any={};
  gameList: any='';
  
  constructor( 
    private toastr: ToastrService, 
    private router: Router, 
    public common: CommonService,
    public spinner: NgxSpinnerService,
    private apiTokenServer: ApiTokenService,
    private storage: LocalStorageService) {  
  }
  async ngOnInit(): Promise<void> {    
    this.isUserLoggedIn = JSON.parse(await localStorage.getItem('isUserLoggedIn'));
    let localGame = await this.storage.retrieve('gameList');
    this.gameList = localGame.find(x=>x.name == this.providerCode);
    if(this.providerCode != null && this.providerCode != undefined){     
      this.gameBalance = await this.common.getGameUserBalance(this.providerCode);
      this.getBalance.emit(this.gameBalance);
    }    
  }
  

}

import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from "ngx-spinner";
import { LocalStorageService } from 'ngx-webstorage';
import { ApiTokenService } from '@services/index';
import axios from 'axios';

@Component({
  selector: 'app-ads-slider',
  templateUrl: './ads-slider.component.html',
  styleUrls: ['./ads-slider.component.scss']
})
export class AdsSliderComponent {
  adsList: any;
  marqueeText: any;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    items: 1,
    autoplay: true,
    //autoplayTimeout: 3000,
    autoplaySpeed: 5000,
    //animateOut: 'fadeOut',
    // animateIn: 'slideInRight',
    // animateOut: 'slideOutLeft',
    // animateIn: 'flipInX',
    navSpeed: 700,
    autoHeight: true,
    navText: ['', ''],
    responsive: {
      400: {
        items: 1
      },
      // 0: {
      //   items: 1
      // },
      // 400: {
      //   items: 2
      // },
      // 740: {
      //   items: 3
      // },
      // 940: {
      //   items: 4
      // }
    },
    // nav: true
  }

  constructor(private storage: LocalStorageService,
    private apiTokenServer: ApiTokenService ) {

  }
  ngOnInit(): void {
    this.getAdsList();
    this.getMarqueeText();
  } 
  async getAdsList() {   
    //call from local storage
    this.adsList=await this.storage.retrieve('myadsList');

    this.apiTokenServer.get(`ads/getAdslistbyproviderID?gameproviderId=0`)
    .then(response => {   
      this.adsList = response.data;         
      this.storage.store('myadsList', this.adsList);
    })
    .catch(error => {
      
    });
  }
  async getMarqueeText() {
      //call from local storage
      this.marqueeText=await this.storage.retrieve('localmarqueeText');

      this.apiTokenServer.get(`marquee/getMarqueeText?providerId=0`)
    .then(response => {     
      this.marqueeText = response.data;         
      this.storage.store('localmarqueeText', this.marqueeText);
    })
    .catch(error => {
     
    });
  }
}

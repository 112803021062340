import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService, } from '@services/navigation.service';
import { ApiTokenService,} from "@services/index";

@Component({
  selector: 'headerbar',
  templateUrl: './headerbar.component.html',
  styleUrls: ['./headerbar.component.scss']
})
export class HeaderbarComponent {
  @Output() myEvent = new EventEmitter();  
  @Input() titleonly: boolean= true;
  @Input() showLogo: boolean= false;
  @Input() hideRefresh: boolean = false;
  @Input() btnAdd: boolean =  false;
  @Output() add = new EventEmitter();  
  @Input() showBorder: boolean= true;
  @Input() showNoti: boolean= true;  

  isClose = false;
  notiCount: any=0;
  userProfileModel: any;
  isUserLoggedIn:  boolean=false;
  constructor(
    private router: Router,
    private navigation: NavigationService,
    private apiTokenServer: ApiTokenService) {
  }
  async ngOnInit(): Promise<void> {
    this.isUserLoggedIn = JSON.parse(await localStorage.getItem('isUserLoggedIn')); 
    if(this.isUserLoggedIn){
      this.userProfileModel = await this.apiTokenServer.getUserProfile();    
    } 
    
    var localnoti = localStorage.getItem("localNewNotiCount");    
    if(localnoti != null || localnoti != undefined)
     {this.notiCount = localnoti} else {this.notiCount=0;}      
    this.isClose = true;
  }
 
  refreshPage(): void {
    this.ngOnInit();
    this.myEvent.emit();
  }
  back(): void {
    this.navigation.back();
  } 
  Add() {
    this.add.emit(true);    
  }  
  logOut() {         
    localStorage.removeItem("token_en"); 
    localStorage.removeItem('isUserLoggedIn'); 
    this.router.navigate(['/account/login'], { replaceUrl: true });  
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
// services
import {EncryptionDecryptionService, CommonService, ApiTokenService } from "@services/index";

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.scss']
})
export class AccountLoginComponent implements OnInit {
 
  userProfileModel: any;
  isUserLoggedIn: boolean= false;
  showAmount: boolean = false;
  showBalance: any='0';
  @Input() hideButton: boolean= false;
  @Input() showProfile: boolean = false;
  constructor( 
    private toastr: ToastrService, 
    private router: Router, 
     
    public enc_dec: EncryptionDecryptionService,
    public common: CommonService,
    public spinner: NgxSpinnerService,
    private apiTokenServer: ApiTokenService    
    ) {   
    
  }

  async ngOnInit(): Promise<void> {      
    this.isUserLoggedIn = JSON.parse(await localStorage.getItem('isUserLoggedIn'));  
    if(this.isUserLoggedIn){
      this.showAmount = JSON.parse( await localStorage.getItem('showAmount'));   
      this.userProfileModel= JSON.parse(await localStorage.getItem("userProfile"));
      this.userProfileModel = await this.apiTokenServer.getUserProfile();      
      if(this.showAmount){
        this.showBalance = this.userProfileModel.balance;
      }  
      else{
        this.showBalance = "*****";
      }   
    } 
     
    

  }
  loginAccount() {
    this.router.navigate(['/account/login'], { replaceUrl: true })
  } 
  showPassword(show: any) {
    localStorage.setItem('showAmount', show);
    this.showAmount = show;
    if(this.showAmount) {
      this.showBalance = this.userProfileModel.balance;
    }
  }
  openWalletPage() {
    if(!this.showProfile) this.router.navigate(['/wallet'], { queryParams: {show: false}, replaceUrl: true });
    else this.router.navigate(['/profile/detail'], { replaceUrl: false });

  }


}

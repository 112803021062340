<headerMenuBar [showLogo]="true" > {{ 'helloword' | translate }}</headerMenuBar>
<div class="content-wrapper">    
  <app-account-login></app-account-login>
  <app-ads-slider></app-ads-slider>
  
  <div class="container-fluid py-0">    
    <div class="row">      
      <div class="col-4 px-2 py-2">
        <div class="hex-main" [routerLink]="['/d-page/twod-page']">
          <img src="assets/img/homeicon/2D.jpg" alt="">
          
        </div>
        <h3 class="text-color text-center">2D</h3>
      </div>      
      <div class="col-4 px-2 py-2">
        <div class="hex-main" [routerLink]="['/d-page/threed-page']">
          <img src="assets/img/homeicon/3D.jpg" alt="">       
        </div>
        <h3 class="text-color text-center">3D</h3>
      </div>
      <div class="col-4  px-2 py-2" *ngFor="let gameProviderObj of gameProviderList">
        <div class="hex-main">
          <a [routerLink]="['/game/List']" [queryParams]="{providerId: gameProviderObj.id,providerCode: gameProviderObj.name }">
            
            <img [src]="gameProviderObj.imageUrl" alt="" loading="lazy">            
            </a>
        </div>
        <h3 class="text-color text-center">
          {{gameProviderObj.display_name}}</h3>
      </div>
    </div>
  </div>
</div>

<app-navigation-bar></app-navigation-bar>

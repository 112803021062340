
    <!-- <div class="appBottomMenu">
        <a (click)="openPage('/home')" class="item"
            [class]="(routerName=='/home' || routerName=='/home/'+deviceId) ? 'active':''">
            <div class="col">
                <ion-icon name="{{routerName=='/home' || routerName=='/home/'+deviceId ? 'home' : 'home-outline'}}"></ion-icon>
                <span class="active-title">{{ 'homeTap' | translate}}</span>
            </div>
    
        </a>
        <a (click)="openPage('/wallet')" class="item" [class]="routerName=='/wallet'? 'active':''"
            *ngIf="isUserLoggedIn">
            <div class="col">
                <ion-icon name="{{routerName=='/wallet' ? 'wallet' : 'wallet-outline'}}"></ion-icon>
                <span class="active-title">{{ 'walletTap' | translate}}</span>
            </div>
        </a>
    
        <a (click)="openPage('/promotion')" class="item" [class]="routerName=='/promotion'? 'active':''"
            *ngIf="isUserLoggedIn">
            <div class="col">
                <ion-icon name="{{routerName=='/promotion' ? 'megaphone' : 'megaphone-outline'}}"></ion-icon>
              
                <span class="active-title">{{ 'promotionTap' | translate}}</span>
            </div>
        </a>
        <a (click)="openPage('/service/service-phone')" class="item"
            [class]="routerName=='/service/service-phone'? 'active':''">
            <div class="col">
                <ion-icon name="{{routerName=='/service/service-phone'  ? 'call' : 'call-outline'}}"></ion-icon>
                <span class="active-title">{{ 'serviceTap' | translate}}</span>
            </div>
        </a>
        <a (click)="openPage('/profile/me-page')" class="item" [class]="routerName=='/profile/me-page'? 'active':''">
            <div class="col">
                <ion-icon name="{{routerName=='/profile/me-page'  ? 'person' : 'person-outline'}}"></ion-icon>
                <span class="active-title">{{ 'meTap' | translate}}</span>
            </div>
        </a>
    
    </div>  -->


<div class="footer-area">
    <div class="footer-top pt-0 text-center">
    </div>
    <div class="container">
        <div class="footer-bottom text-center">
            <ul> 
                <li *ngIf="isUserLoggedIn"  (click)="openPage('/wallet')">
                    <a [class]="routerName=='/wallet'? 'active' : ''" >
                        <i class="fas fa-wallet"></i>
                    </a>                   
                </li>
                <li (click)="openPage('/service/service-phone')" >
                    <a [class]="routerName=='/service/service-phone'? 'active ' : ''">
                        <i class="fas fa-phone"></i>                       
                    </a>                   
                </li>
               
                <li (click)="openPage('/home')">      
                    <a  class="ba-add-balance-btn" [class]="(routerName=='/home' || routerName=='/home/'+deviceId) ? 'active' : ''">                        
                        <img src="assets/img/icon/home.webp" alt="" > 
                    </a> 
                </li>
                <li *ngIf="isUserLoggedIn" (click)="openPage('/promotion')">
                    <a [class]="routerName=='/promotion'? 'active' : ''" >
                        <i class="far fa-comments"></i>                       
                    </a>                   
                </li>
                <li (click)="openPage('/profile/me-page')" >
                    <a [class]="routerName=='/profile/me-page'? 'active' : ''">
                        <i class="fas fa-bars"></i>                        
                    </a>                    
                </li>
            </ul>
        </div>
    </div>
</div>

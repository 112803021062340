<headerbar>{{'helloword' | translate}}</headerbar>


<div id="appCapsule" class="main__wrapper">
    <div class="container">
        <div class="row">
            <h3 class="text-center">game ၀င်ဆော့ရန်အတွက်  Iphone setting ကို အောက်ပါအတိုင်းလုပ်ဆောင်ရပါမည်။</h3>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="img">
                    <img src="assets/img/Step1.jpg" alt="" width="200" height="auto">
                </div>

            </div>
            <div class="col-6">
                <div class="img">
                    <img src="assets/img/Step2.jpg" alt="" width="200" height="auto">
                </div>  

            </div>
        </div>
    </div>
   
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class AppNavigationBarComponent implements OnInit, OnDestroy {
  promotionCount : any;
  promotionList : any;
  token : any;
  routerName: any;
  isUserLoggedIn: any;
  deviceId: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router, 
    private storage: LocalStorageService,) 
    {
      this.deviceId=this.storage.retrieve('localDeviceId'); 
    }
  async ngOnInit(): Promise<void> 
  {
   
    this.isUserLoggedIn= JSON.parse(await localStorage.getItem("isUserLoggedIn"));
    this.routerName = this.router.url;
    this.promotionCount = this.storage.retrieve('localpromotionCount');
    
  }
 ngOnDestroy(): void {
     this.spinner.hide();
 }
  openPage(routeUrl:string) {   
   //alert(routeUrl)
    if(routeUrl =='/home'){  
      if(this.deviceId != null){       
        this.router.navigate([routeUrl,this.deviceId]); 
        return;
      } 
      else{
        this.router.navigate([routeUrl]); 
        return;
      }    
    }
    this.router.navigate([routeUrl]);    
}



 
}

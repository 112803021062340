import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/map';
import {  } from "@services/index";

@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.scss']
})
export class LanguagePageComponent implements OnInit {

  selectedIndex: any;
  // supportLanguages: any;
  lang: any;
  supportLanguages = ['en','my','th','zh'];
  @Input() left: boolean= false;
  constructor(    
    private translateService: TranslateService,    
    private storage: LocalStorageService,) {   
  
  }

  async ngOnInit() {
    if(this.storage.retrieve('localLanguageIndex') == null){
      this.selectedIndex = this.storage.store('localLanguageIndex',0);
    }
    else{
      this.selectedIndex=this.storage.retrieve('localLanguageIndex');
    }  
        
    let lang = await localStorage.getItem('Language');
    if(lang == null || lang == '')
    {
      localStorage.setItem('Language', 'en');    
      this.lang= await localStorage.getItem('Language');     
    }     
    else{
      this.translateService.setDefaultLang(lang); 
      this.lang= await localStorage.getItem('Language');
     
    }   
   
    this. changeLanguage(); 
    this.lang= await localStorage.getItem('Language');
  }

  changeLanguage(){   
     
    this.lang= this.supportLanguages[this.selectedIndex]; 
  }

  selectedLanguageCountry(lang: string,index: number){ 
   
    this.selectedIndex = index;
    this.translateService.use(lang);
    localStorage.setItem('Language', lang); 
    this.storage.store('localLanguageIndex', this.selectedIndex); 
    var element = document.getElementById("body");   
      if(lang == 'my'){
        element.classList.remove("body__en");
        element.classList.remove("body__mm");
        element.classList.add("body__mm");        
      }
      else{
        element.classList.remove("body__mm");
        element.classList.remove("body__en");
        element.classList.add("body__en");
      }   
    this.changeLanguage();  
  }

}

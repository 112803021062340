<div class="container-fluid " *ngIf="!isUserLoggedIn">
    <div class="ba-balance-inner text-end align-self-center" [routerLink]="['/account/login']">
      <div class="icon">
        <i class="fas fa-user-circle" style="font-size: 28px; color: #fff;"></i> 
      </div>    
      <h5 class="title"> {{ 'click_to_login' | translate}} </h5>  
    </div>
  </div>
  <div class="container-fluid"  *ngIf="isUserLoggedIn">
    <ul class="transaction-inner">
        <li class="ba-single-transaction" style="border-bottom: 6px solid #eee; margin-bottom: 0px; padding: 10px;">
            <div class="thumb">                
              <img src="assets/img/icon/money.png" alt="" style="background-color: #11A44C;">    
            </div>
            <div class="details">              
                <h5>{{'balance' | translate}}</h5>
                <h5 class="pt-1 balance_text">{{ gameBalance.mainBalance | number : '1.0-2'}} {{'ks' | translate}}</h5>   
            </div>
        </li>   
        <li class="ba-single-transaction" style="border-bottom: 6px solid #eee; margin-bottom: 0px; padding: 10px;" [routerLink]="['/game/wallet']" [queryParams]="{providerId: providerId,providerCode: providerCode}">
            <div class="thumb">               
                <img [src]="gameList.imageUrl" alt="img">
            </div>
            <div class="details">               
                <h5>{{gameList?.display_name}}</h5>
                <h5 class="pt-1 balance_text">{{ gameBalance.balance | number : '1.0-2'}} {{ 'ks' | translate}}</h5>  
                <h5 class="amount text-end" style="right: 30px;">  
                  <i class="fas fa-angle-right btn_drown" ></i>  
              </h5>                                 
            </div>
        </li>             
    </ul> 
    <!-- <div class="ba-balance-inner col-12 text-end">    
      <div class="icon">
        <img src="assets/img/icon/money.png" alt="">     
      </div>  
      <div class="row">
        <div class="col-8">
          <div  class="pl-2">
            <div class="balance__wrapper">
              <h5>{{'balance' | translate}}</h5> 
            </div>
            <h3 class="wallet-amount">{{ gameBalance.mainBalance | number : '1.2-2'}}</h3>        
            </div>       
        </div>      
      </div>     
    </div> -->
    <!-- <div class="ba-balance-inner mt-2 text-end">    
      <div class="game_logo_div" [style.background-image]="'url('+gameList.imageUrl+')'"> </div>  
      <div class="row"  [routerLink]="['/game/wallet']" [queryParams]="{providerId: providerId,providerCode: providerCode}">
        <div class="col-8">
          <div  class="pl-2">
            <div class="balance__wrapper">
              <h5>{{ gameList.display_name }}</h5>
            </div>
            <h3 class="wallet-amount">{{ gameBalance.balance | number : '1.2-2'}}</h3>          
          </div>         
        </div>
        <div class="col-4 d-flex justify-content-end">
          <span class="wallet-right-arrow" >
            <i class="fas fa-angle-right"></i>         
          </span>
        </div>
      </div>     
    </div>        -->
  </div>
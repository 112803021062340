import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customdateformat'
})
export class CustomdateformatPipe implements PipeTransform {

  transform(date: any, format: string): any {
    if (date) {
     return moment(new Date(date)).format(format);
    }
  }

}
// 
@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends  DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {   
    return super.transform(value, "d MMM, y");
  }
}
@Pipe({
  name: 'customHour'
})
export class CustomHourPipe extends  DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {   
    return super.transform(value, "h:mm a");
  }
}
@Pipe({
  name: 'phoneformat'
})
export class CustomphoneformatPipe implements PipeTransform {

  transform(value: any): any {
    if (value != null) {
      let phone = "*******" +
      value.substring(value.length - 4, value.length).toString();
      return phone;
    }
  
    
  }

}


@Pipe({
  name: "TwodCloseTimeFormat"
})
export class TwodCloseTimeFormatPipe implements PipeTransform {

  // constructor(@Inject(forwardRef(() => TwodCloseTimeComponent)) private comp: TwodCloseTimeComponent) {

  // }
  transform(value: number): string {
    // const hours: number = Math.floor(value / 3600);
    // const minutes: number = Math.floor((value % 3600) / 60);
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    const seconds: number = Math.floor(value % 60);
    if (hours >= 0) {
      return (
        ("00" + hours).slice(-2) +
        ":" +
        ("00" + minutes).slice(-2) +
        ":" +
        ("00" + seconds).slice(-2)
        //("00" + Math.floor(value - minutes * 60)).slice(-2)
      );
    } 
    

  }
}

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateService } from '@ngx-translate/core';
import {  } from '@baseUrl/src/app/service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { forkJoin, throwError } from 'rxjs';

declare function getLiveDataforStream(): any;
@Component({
  selector: 'app-stream-page',
  templateUrl: './stream-page.component.html',
  styleUrls: ['./stream-page.component.scss']
})
export class StreamPageComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
    // animateOut: 'fadeOut',
    // animateIn: 'slideInRight',
    // animateOut: 'slideOutLeft',
    // animateIn: 'fadeIn', // add this
    animateOut: 'fadeOut' ,// and this
    animateIn: 'flipInX',
    navSpeed: 100,
    autoHeight: true,
    navText: ['', ''],
    responsive: {
      400: {
        items: 1
      },
      // 0: {
      //   items: 1
      // },
      // 400: {
      //   items: 2
      // },
      740: {
        items: 1
      },
      // 940: {
      //   items: 4
      // }
    },
    // nav: true
  }

  public clock: any;
  public todaydate: any;
  public streamDatas:any;
  constructor(private http: HttpClient,  private storage: LocalStorageService,
    private toastr: ToastrService, private translateService: TranslateService,) {
    document.body.className = "full-screen-size";
    this.todaydate = new Date();
    setInterval(() => {
      this.clock = moment().format('h:mm:ss a');
    }, 1000) // Updates the time every second.
  }

  ngOnInit(): void {
    getLiveDataforStream();
    this.getData();
  }

  getData() {
  //  console.log('reach there')
    let headers = new HttpHeaders();
    forkJoin(
      {
        tableData: this.http.get('stream/get2dForLiveStream',{headers:headers}),
        referralCode:this.http.get('stream/getStremReferralCode',{headers:headers}),
        moderninternet:this.http.get('stream/getStreamModernInternet',{headers:headers}),
        service:this.http.get('service/listService',{headers:headers}),
      }
    ).pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
        //  console.log(result)
          this.streamDatas=result;
          this.streamDatas.service=result.service.find(service=> service.title=='stream_link')
   
          //console.log(this.streamDatas)
        });

  }

  getStremReferralCode(){
    
  }


  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      this.storage.clear('token');
      this.storage.clear('isUserLoggedIn');
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  ngOnDestroy() {
    document.body.className = "";
  }

}

import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule } from '@angular/forms';
import {  
  AppNavigationBarComponent,
  HeaderMenuBarComponent,
  HeaderbarComponent,
  LanguagePageComponent,
  LanguageTextPageComponent,
  AdsSliderComponent, 
  AccountLoginComponent, 
  PageHeaderDetailComponent, 
  OtpServicePhoneComponent,
  SharedTableComponent,
  QrReaderComponent,
  AppPhonePickerComponent,
} from './basic-components/index';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { NgxSpinnerModule } from 'ngx-spinner';
// pipes
import { TimeAgoExtendsPipe } from "./pipes/time-ago.pipe";
import { RouterModule } from '@angular/router';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ContactBoxComponent } from './basic-components/contact-box/contact-box.component';
import { CustomdateformatPipe,TwodCloseTimeFormatPipe, CustomphoneformatPipe, CustomDatePipe,CustomHourPipe } from "./pipes/customdateformat.pipe"
import { MaterialModule } from '@baseUrl/src/app/component/agents/agents-material.module';
import { QRCodeModule } from 'angularx-qrcode';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ModalModule, BsModalService} from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GameWalletAccountComponent } from './basic-components/game-wallet-account/game-wallet-account.component';


const com = [
  AppNavigationBarComponent,
  HeaderMenuBarComponent,
  HeaderbarComponent,
  LanguagePageComponent,
  LanguageTextPageComponent,
  AdsSliderComponent, 
  AccountLoginComponent,
  PageHeaderDetailComponent,
  OtpServicePhoneComponent,
  ContactBoxComponent,
  SharedTableComponent,
  QrReaderComponent, 
  AppPhonePickerComponent,
  GameWalletAccountComponent,  
];

const pipes = [
  TimeAgoExtendsPipe,
  SafeUrlPipe,
  CustomdateformatPipe,
  TwodCloseTimeFormatPipe,
  CustomphoneformatPipe,
  CustomDatePipe,
  CustomHourPipe
]
@NgModule({
  declarations: [
    com,
    pipes, 

    
  ],
  imports: [
    FormsModule,
    CommonModule,    
    BsDropdownModule,
    CarouselModule,
    NgxSpinnerModule,
    RouterModule,
    MaterialModule,
    NgxScannerQrcodeModule,
    QRCodeModule,  
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); },
        deps: [HttpClient]
      }
    }),  
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  exports: [
    com,
    pipes,
    QRCodeModule,
    NgxSpinnerModule,
    ModalModule,
    FormsModule,
    TranslateModule,
  ],
  providers: [
    pipes,
    BsModalService,
    
  ]
})
export class SharedModule { }

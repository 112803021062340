import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/map';
import {  ApiTokenService } from "@services/index";
import { DeviceUUID } from 'device-uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShowError }  from '../util/utils';
import { Location } from '@angular/common';
@Component({
  selector: 'app-otp-page',
  templateUrl: './otp-page.component.html',
  styleUrls: ['./otp-page.component.scss']
})
export class OtpPageComponent implements OnInit {
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  isOtpService: boolean = false;
  OtpSms: any;
  otpcode: any;
  phoneNumber: any;
  time: number = 120;
  counterTimer;
  interval;
  otpPhoneNumber: any;
  actionType: any;
  updateDeviceId: any;
  deviceId: any;
  phone_no: any;
  ipAddress: any;
  guid: any;
  request_id: any;
  code: any;
  password: any;  
  token: any;
  checkOtpModel: any;
  parentLink: any;
  focus: number=0;
  constructor(private translateService: TranslateService, 
     private toastr: ToastrService,      
     private router: Router, 
     private storage: LocalStorageService,       
     private apiTokenServer: ApiTokenService,
     public spinner: NgxSpinnerService,
     private activatedRoute: ActivatedRoute,
     private location: Location,
     ) {     
    this.activatedRoute.queryParams.subscribe(params=> {
      this.actionType = params.actionType;
    })
   
  }
 
  ngOnInit(): void {   
    
    this.startTimer();   
    if (this.storage.retrieve('localNewDeviceOtpSms') != null) {
      if (this.storage.retrieve('localNewDeviceOtpSms').number != null) {
        this.phoneNumber = "+" + this.storage.retrieve('localNewDeviceOtpSms').number;
      };
    }
    if (this.storage.retrieve('localInsertAccountOtpSms') != null) {
      if (this.storage.retrieve('localInsertAccountOtpSms').number != null) {
        this.phoneNumber = "+" + this.storage.retrieve('localInsertAccountOtpSms').number;
      }
    }
    if (this.storage.retrieve('localPhoneValue') != null) {
      var prefix = this.storage.retrieve('localPhonePrefix');
      var phone = this.storage.retrieve('localPhoneValue');
      if (phone.startsWith('0')) {
        this.phoneNumber = prefix + phone.substring(1, phone.length);
      }
      else {
        this.phoneNumber = prefix + phone;
      }
    }
    
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 120) {
        this.time--;
      }
      if (this.time === 0) {
        this.time = 0;
      } else {
        this.time--;
      }
      this.counterTimer = this.transform(this.time)
    }, 1000);
    return this.counterTimer;
  }
  transform(value: number): string {   
    const seconds: number = Math.floor(value);
    return '' + (value);
  }

  onCodeCompleted(i: number) {
    this.otpcode = i;
    this.validateOtp();
  }

  validateOtp() {
    if (!this.otpcode || this.otpcode.length < 6) {
      $("#passErr").html(this.translateService.instant("otp_required"));
      return false;
    }
    else {
      $("#passErr").html("");
      return true;
    }
  }


  async checkOtp() {   
    let checkOPTINput = this.validateOtp();
    if (!checkOPTINput) {
      return;
    }   
    if (this.actionType == "withdrawAddAccount") {     
      if(this.storage.retrieve('localOtpSms') != null) {   
        this.spinner.show();                   
        let localSms = this.storage.retrieve('localOtpSms');  
        localSms.code= this.otpcode;       
        let modelStr = JSON.stringify(localSms); 
        this.apiTokenServer.post(`userbankaccount/checkbankaccOTP`, modelStr, true)
        .then(async response=>{
          if(response.data.statusCode == 200 && response.data.data != null){        
            this.insertBankAccount();
           }
           else{
           ShowError(response.data.statusCode,this.toastr,response.data.message);  
            return false;            
           }    
        })
        .catch(error=> {            
         ShowError(error.status,this.toastr,error.message); 
        });         
        this.spinner.hide();
      }      
      return;
    }
    if(this.actionType == 'NEWDIVICE'){
      if (this.storage.retrieve('localOtpSms') != null) {      
        let deviceId = new DeviceUUID().get();
        let localSms = this.storage.retrieve('localOtpSms');  
        localSms.code= this.otpcode;
        localSms.phone_no=this.phoneNumber;
        localSms.deviceId= deviceId;
        let modelStr = JSON.stringify(localSms);       
        this.apiTokenServer.post(`User/checkdeviceOTP`,modelStr,true)        
        .then(async response=> {
          let data = JSON.parse(response.data.data);
          if(response.data.statusCode == 200 && data.status == true ){
            this.router.navigate(['/account/login'], { queryParams: {actionType: true}, replaceUrl: true });           
           }
           else{
           ShowError(response.data.statusCode,this.toastr, response.data.message);    
           }    
        })
        .catch(error=> {    
         ShowError(error.status,this.toastr, error.response.message); 
        });      
    }
    }  
    else{
      if(this.storage.retrieve('localOtpSms') != null) { 
        this.spinner.show();   
        let localSms = this.storage.retrieve('localOtpSms');           
        localSms.code= this.otpcode;
        localSms.phone_no= this.phoneNumber; 
        let modelStr = JSON.stringify(localSms);       
        this.apiTokenServer.post(`user/checkOTP`, modelStr ,true)
        .then(async response=> {
          if(response.data.statusCode == 200){     
             let data = JSON.parse(response.data.data);
             if (data != null) {
              if (data.status == true) {
                if(this.actionType == "forgetPassword") {
                  this.storage.clear('localOtpSms');
                  this.router.navigate(['/account/resetPassword'], { replaceUrl: true });
                } if(this.actionType == "register") {
                  this.router.navigate(['/account/registration'], { replaceUrl: true });
                }
                return true;
              } 
              else {
               ShowError(response.data.statusCode,this.toastr,'OTP is not correct');  
                return false;
              }
            }
           }
           else{
           ShowError(response.data.statusCode,this.toastr,response.data.message);  
            return false;
            
           }    
        })
        .catch(error=> {            
         ShowError(error.status,this.toastr,error.message); 
        });         
        this.spinner.hide();
    }
    }     
  }
  //wihtdrawInsert
  async insertBankAccount() {    
    let modelstr = this.storage.retrieve('insertBankBankAccountList');    
    this.apiTokenServer.post(`userbankaccount/createuserBankAccount`,modelstr, true)
    .then(async response=> {   
     let data = response.data;        
      if(data.statusCode == 200){ 
        this.location.back();
      }           
      else{
        ShowError(data.statusCode,this.toastr,response.data.message);
        this.location.back();
      }    
   })          
   .catch(async error=> {    
     ShowError(error.status,this.toastr,error.message);      
     return false;
   });
}

  ResendOtp(url) {
    this.apiTokenServer.getPhone(`${url}`,this.phoneNumber,true)        
    .then(async response=> {
      if (response.data.statusCode == 200) {
        this.storage.store('localOtpSms', JSON.parse(response.data.data));
        if (response.data.body.split('').trim() == "Not valid OTP code") {
         ShowError(response.data.statusCode,this.toastr,'OTP is not correct');
         return null;
        }
        if (response.data.body.split('').trim() == "Try Again") {
         ShowError(response.data.statusCode,this.toastr,response.data.body.toString());        
          return null;
        }
      }      
      else{
       ShowError(response.data.statusCode,this.toastr, response.data.message);    
      }    
    })
    .catch(error=> {    
     ShowError(error.status,this.toastr, error.response.message); 
    });    

   
  }
  getOtp() {
    this.codeInput.reset();   

    this.time = 120;
    this.startTimer();
    if (this.actionType == "forgetPassword") {
      this.ResendOtp("user/getForgotPassowrdOTP");
      return
    }
    if (this.actionType == "withdrawAddAccount") {
      this.ResendOtp("userbankaccount/getBankAccOTP");
      return;
    }
    if(this.actionType == "NEWDIVICE"){
      this.ResendOtp("user/getdeviceRegisterOTP");
      return
    }
    else {
      this.ResendOtp("user/getRegisterOTP");
      return;
    }
  }
 
  goToServicePhone() {
    this.isOtpService = true;
  }
  showOptPage(e: boolean) {
    this.isOtpService = e;
  }
}

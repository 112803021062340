import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import * as JsEncryptModule from 'jsencrypt';
import { publicKey } from '../config';
import { Platform } from '@angular/cdk/platform';

interface PromiseCallback {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
}

@Injectable({
  providedIn: 'root'
})
export class ApiTokenService {
  private axiosInstance: AxiosInstance;
  // private enc_dec EncryptionDecryptionService;
  private tokenKey: string="7061737323PPAQWE";
  private isRefreshing: boolean;
  private failedRequests: Array<PromiseCallback>;
  public ipaddress: any;
  constructor(
    public platform: Platform,
    private router: Router, 
    private toast: ToastrService, ) {
    this.axiosInstance = axios.create();
    this.isRefreshing = false;
    this.failedRequests = [];
    this.setupInterceptors();

    //dot NEt 
    //this.ipaddress = "https://localhost:7059/api/";
    //dot NEt    
    // this.ipaddress = "https://localhost:44383/api/";

    //pro      
     this.ipaddress = "https://api.funapp24.com/api/";     
    //test
    //this.ipaddress = "https://demoapi.funapp24.com/api/"; 
  }
  generateAesKey() {
    if(this.platform.SAFARI || this.platform.IOS){
      return this.axiosInstance.get(this.ipaddress+"user/genk").then(respone=>{
        if(respone.status == 200){        
          return respone.data;
         }
      });
    }
    else {
      return window.crypto.subtle.generateKey(
        {
          name: 'AES-CBC',
          length: 256
        },
        true,
        ['encrypt', 'decrypt']
        )
        .then(key => {
          return window.crypto.subtle.exportKey('raw', key);
        })
        .then(buffer => {       
          const keyArray = Array.from(new Uint16Array(buffer));
          const keyHex = keyArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
          const key= keyHex.substring(0, 16).toLocaleUpperCase();
          return key;
        });
    }
  }
  private RSAEncryptKey(key){    
   let rsaEnc= new JsEncryptModule.JSEncrypt();
   rsaEnc.setPublicKey(publicKey);
    let enc = rsaEnc.encrypt(key);
    return enc;    
  }
  private aesDecry(key, data){
    if(key==undefined||data==undefined ||key==null||data==null ||key==""||data==""){
      return null;
    }
  let _key = CryptoJS.enc.Utf8.parse(key); 
   return CryptoJS.AES.decrypt(
      data, _key, {
       keySize: 16,
       iv: _key,
       mode: CryptoJS.mode.CBC,
       padding: CryptoJS.pad.Pkcs7
     }).toString(CryptoJS.enc.Utf8);  
  }
  
  private aesEncrypt(key, data){
    if(key==undefined||data==undefined ||key==null||data==null ||key==""||data==""){
      return "";
    }
   let _key = CryptoJS.enc.Utf8.parse(key);     
   let enc= CryptoJS.AES.encrypt(
       data, _key, {
       keySize: 16,
       iv: _key,
       mode: CryptoJS.mode.CBC,//ECB,
       padding: CryptoJS.pad.Pkcs7
     });  
     return enc.toString()
  }
//   public async encryptDataToAes(data) {      
//     let aesKey = await this.generateAesKey();
//     let rsaEnc= new JsEncryptModule.JSEncrypt();  
//     rsaEnc.setPublicKey(publicKey);
   
//     let _key = CryptoJS.enc.Utf8.parse(aesKey);     
//     let enc= CryptoJS.AES.encrypt(
//         data, _key, {
//         keySize: 16,
//         iv: _key,
//         mode: CryptoJS.mode.CBC,//ECB,
//         padding: CryptoJS.pad.Pkcs7
//       });
//      // return enc.toString();
//       return enc.toString();
// }
  private setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const accessToken= localStorage.getItem("token_en");
        const asekey = CryptoJS.enc.Utf8.parse(this.tokenKey); 
        let token="";
        if(accessToken!=null){
          token = await this.aesDecry(this.tokenKey, accessToken);
          
        } 
       
     
        if (token!=null && token!=undefined) {
          config.headers = {
            ...config.headers,
            Authorization: "Bearer "+token
          };
        }else{
         
          return Promise.reject(new Error('needlogin'));
        }
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if(error.message=="needlogin"){
         
          this.processFailedRequests(error);
          return Promise.reject(error);
        }
       
        const originalRequest = error.config;

        if(error.config.timeout ==0 && error.response?.status == 403 ){
          this.isRefreshing = false;
          console.log(JSON.stringify(error))
          localStorage.removeItem('token_en');
          
          this.router.navigate(['/user-block'], { state: {message: error.response.data}, replaceUrl: true });          
          this.processFailedRequests(error);
          return Promise.reject(error);
        } 
        // Retry the request if it failed due to an expired token
        if (error.config.timeout == 0||error.response?.status === 401 && !originalRequest['retryAttempt']) {         
          if (this.isRefreshing) {
            // If token is already being refreshed, add the request to the failedRequests array
            return new Promise((resolve, reject) => {
              this.failedRequests.push({ resolve, reject });
            }).then(() => {
              return this.axiosInstance(originalRequest);
            });
          }

          originalRequest['retryAttempt'] = true;
          this.isRefreshing = true;
          return  await this.refreshAccessToken()
            .then((newToken) => {
              if (newToken) {
                // Update the new access token and retry the failed requests
              //  this.updateAccessToken(newToken);
                originalRequest.headers = {
                  ...originalRequest.headers,
                  Authorization: `Bearer ${newToken}`
                };

                // Retry the original request and resolve the pending promises
                this.processFailedRequests(null, newToken);
                return this.axiosInstance(originalRequest);
              }
              // If refreshing the token failed, redirect to the login page or handle the error as needed
              // For example: window.location.href = '/login';
              this.processFailedRequests(error);
              return Promise.reject(error);
            })
            .catch(() => {
              // Refreshing the token failed, redirect to the login page or handle the error as needed
              // For example: window.location.href = '/login';
              this.processFailedRequests(error);
              return Promise.reject(error);
            })
            .finally(() => {
              this.isRefreshing = false;
              this.failedRequests = [];
            });
        }
        return Promise.reject(error);
      }
    );
  }

  private processFailedRequests(error: AxiosError | null, newToken?: string) {
    this.failedRequests.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(newToken);
      }
    });
  }

  private async refreshAccessToken(): Promise<string | null> {                   
          //end tokenkey    
          let rsaEnc =this.RSAEncryptKey(this.tokenKey); 
          const encKey= encodeURIComponent(rsaEnc);            
          let token = localStorage.getItem("token_en");          
          let formData = new FormData();
          formData.append('data', token);  
          let data = await axios.post(`${this.ipaddress}authenticate/RefreshToken?secretKey=${encKey}`,formData)
          .then(async response=> {   
           // console.log(JSON.stringify(response.data)) 
            let data= response.data;        
            if(data.statusCode == 200){  
              // console.log(JSON.stringify(data))               
                  let token = await this.aesDecry(this.tokenKey, response.data.data);                     
                  localStorage.setItem('token_en', response.data.data); 
                  return token;
             }             
             else{
              localStorage.removeItem('token');
              this.toast.error('', response.data.message,{
                timeOut: 6000,
                positionClass: 'toast-top-center',
              });
              this.router.navigate(['/login'],{replaceUrl: true});
              return null;              
             }    
          })          
          .catch(error=> {  
            return null;    
          });
          return data;
  }

  async get(url: string,isEncry: boolean=false) {
    if(isEncry){
      let aesKey= await this.generateAesKey();
      let secretKey= await this.RSAEncryptKey(aesKey);
      if(url.includes('?')){
        url+="&secretKey="+encodeURIComponent(secretKey);
      }else{
        url+="?secretKey="+encodeURIComponent(secretKey);  
      }     
    return  this.axiosInstance.get(this.ipaddress+url).then(respone=>{
        if(respone.status == 200){
          respone.data.data=this.aesDecry(aesKey,respone.data.data);
          return respone;
         }
      });

    }else{
      return this.axiosInstance.get(this.ipaddress+url);
    }    
  }
  async getPhone(url: string,phone: string,isEncry: boolean=false) {
    if(isEncry){
      let aesKey= await this.generateAesKey();
      let secretKey= await this.RSAEncryptKey(aesKey);
      if(url.includes('?')){
        url+="&secretKey="+encodeURIComponent(secretKey);
      }else{
        if(phone != ''){
          let phoneSec = await this.aesEncrypt(aesKey, phone);
          url+="?phoneNo="+encodeURIComponent(phoneSec)+"&secretKey="+encodeURIComponent(secretKey);  
        }
        else{
          url+="?secretKey="+encodeURIComponent(secretKey);  
        }       
      }     
    return  this.axiosInstance.get(this.ipaddress+url).then(respone=>{
        if(respone.status == 200){
          respone.data.data=this.aesDecry(aesKey, respone.data.data);
          return respone;
         }
      });

    }else{
      return this.axiosInstance.get(this.ipaddress+url);
    }    
  }
  async getStr(url: string) {
    return this.axiosInstance.get(this.ipaddress+url);
  }
  async post(url: string, data: any, isEncry:boolean=false, formData: boolean=true) {
    if(isEncry){
      let aesKey=await this. generateAesKey();
      data = await this.aesEncrypt(aesKey,data);
      let dataToPost = null;
      if(formData){      
        dataToPost = new FormData();        
        dataToPost.append('data', data);      
      }
      else dataToPost=data;
      let secretKey=await this.RSAEncryptKey(aesKey);
      if(url.includes('?')){
        url+="&secretKey="+encodeURIComponent(secretKey);
      }else{
        url+="?secretKey="+encodeURIComponent(secretKey);
      }
      return this.axiosInstance.post(this.ipaddress+url, dataToPost).then(async response=>{
       if(response.status == 200){  
        if(response.data.data != null){
          response.data.data = await this.aesDecry(aesKey,response.data.data);          
        }
        return response;  
       }else{
        return response;
       }
       });
    }
    else return this.axiosInstance.post(this.ipaddress+url, data);
  }
  async login(url: string, data: any) {   
    localStorage.removeItem('token_en');   
    data= await this.aesEncrypt(this.tokenKey,data);
    let formData = new FormData();  
    formData.append('data', data);
    let secretKey= await this.RSAEncryptKey(this.tokenKey);
    if(url.includes('?')){
      url+="&secretKey="+encodeURIComponent(secretKey);
    }else{
      url+="?secretKey="+encodeURIComponent(secretKey);
    }
    return this.axiosInstance.post(this.ipaddress+ url, formData).then(async response=>{
     if(response.status == 200){     
      if(response.data.message !=  "Token" && response.data.data != null){        
        response.data = await this.aesDecry(this.tokenKey,response.data.data);         
      }    
      return response;
     }else{
      return response;
     }
     });   
   
  }

  async getUserProfile() {   
    let isUserLoggedIn: any= false; 
    let userData = await this.get(`User/userprofile`, true)        
    .then(async response=> {   
      let data= response.data;        
      if(data.statusCode == 200){    
            isUserLoggedIn = true;
            let userModel = JSON.parse(data.data);
            localStorage.setItem("userProfile", data.data);
            localStorage.setItem('isUserLoggedIn', isUserLoggedIn);
            return userModel;
       }             
       else{        
        localStorage.setItem('isUserLoggedIn', isUserLoggedIn);
        return null;              
       }    
    })          
    .catch(async error=> { 
      localStorage.setItem('isUserLoggedIn', isUserLoggedIn);
      return null;           
    });
    return userData;
  }
  async getSectionList(){
    let res;
    let local = JSON.parse(await localStorage.getItem('sectionList'));
    if(local != null && local != undefined){
      res = local;
    }
    await this.get(`twodsection/getTwodSectionList`)        
    .then(response=> { 
      localStorage.setItem('sectionList', JSON.stringify(response.data));
      res = response.data;
    })          
    .catch(error=> {       
      return null;           
    });
    return res;    
  }
  async getDateTime() {    
    let response = await this.get('value/getDateTime')
    .then(response => {
      return response.data.utc_datetime;
    })
    .catch(error => {
      return null;
    });
    return response;
      
  }
  async aesEncrptData(data){   
    let enc = await this.aesEncrypt(this.tokenKey, data);
    return enc;
  }
  async aesDecryptData(data: any){    
    let dec = await this.aesDecry(this.tokenKey, data);
    return dec;
  }
  async getMyLevel(){   
    let response = await this.get('level/Getvip')
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return null;
    });
    return response;   
  }
  
  async getVIPByUserId() {  
    let response = await this.get('level/getviplistbyuserid')
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return null;
    });
    return response; 
  }
  // getMyUsers(FromDate: string, ToDate: string, referral_code: string): Observable<Column[]> {
  //   let url = `agent/GetAgentCommession?FromDate=${FromDate}&ToDate=${ToDate}&referral_code=${referral_code}`
  //   return this.http.get<Column[]>(url)
  // }

  // async getTopUpUsers(FromDate: string, ToDate: string, phno: string): Promise<Observable<Column[]>> {
  //   const accessToken =await this.enc_dec.TokenDecryptedData();
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', accessToken);
  //   let url = `agent/GetTopupUsers?FromDate=${FromDate}&ToDate=${ToDate}&phone_no=${phno}`
  //   return this.http.get<Column[]>(url, { headers: headers })
  // }

  // async getNoTopUpUsers(FromDate: string, ToDate: string, phno: string): Promise<Observable<Column[]>> {
  //   const accessToken =await this.enc_dec.TokenDecryptedData();
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', accessToken);
  //   let url = `agent/GetNoTopupUsers?FromDate=${FromDate}&ToDate=${ToDate}&phone_no=${phno}`
  //   return await this.http.get<Column[]>(url, { headers: headers })
  // }

  // async getTopupUsersCount(phno: string) {
  //   const accessToken =await this.enc_dec.TokenDecryptedData();
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', accessToken);
  //   let url = `agent/GetTopupUserscount?phone_no=${phno}`
  //   return this.http.get<number>(url, { headers: headers })
  // }

  // async getNoTopupUsersCount(phno: string) {
  //   const accessToken =await this.enc_dec.TokenDecryptedData();
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', accessToken);
  //   let url = `agent/GetNoTopupUserscount?phone_no=${phno}`
  //   return this.http.get<number>(url, { headers: headers })
  // }
}
